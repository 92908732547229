import { Profile } from '@magicyard/shared/platform/lib/api';
import React, { useEffect, useState } from 'react';
import './YardAndDisplayScreen.css';
import 'share-api-polyfill';
import TakePicDialog from '@magicyard/magicsnakes-shared/components/TakePicDialog';
import { track, updateAnalyticsData } from '@magicyard/shared/src/localAnalytics';
import { getYardInviteLink } from '@magicyard/utils';
import { SubmittableInput } from './submittable-input/SubmittableInput';
import { Dialog, IconButton, Slide } from '@material-ui/core';
import Background from './Background/Background';
import Close from '@material-ui/icons/Close';
import { YardWithDisplayController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { DEFAULT_NAME, PopupContainer } from '../App';
import { GameButton } from '@magicyard/magicsnakes-shared/components/GameButton/GameButton';

const MIN_DEV_CLICKS = 5;

export const YardAndDisplayScreen = ({
  controller,
  onSubmitOnline,
  onSubmitLocal,
  onProfileUpdate,
  setDevState,
}: {
  controller: YardWithDisplayController;
  onSubmitOnline: (extras?: any) => void;
  onSubmitLocal: (extras?: any) => void;
  onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void;
  setDevState: (devState: boolean) => void;
}) => {
  const [devClickCount, setDevClickCount] = useState(0);
  const [showStartPopup, setShowStartPopup] = useState(false);
  const [showInvitePopup, setShowInvitePopup] = useState(false);

  const shouldShowDev = devClickCount > MIN_DEV_CLICKS;

  useEffect(() => {
    updateAnalyticsData({
      yardId: controller.yard.id,
      controllerId: controller.profile.id,
      displayId: controller.displayId,
      numDisplaysConnected: controller.yard.displays?.length ?? 0,
    });
  }, [controller]);

  useEffect(() => track('Yard With Display Loaded'), []);

  const startPrivateGame = () => {
    track('Start Game Clicked', { devMode: shouldShowDev });
    onSubmitLocal();
  };

  // const startOnlineGame = () => {
  //   track('Online Game Requested');
  //   onSubmitOnline();
  // };

  const handleInviteClick = () => {
    setShowInvitePopup(true);
    track('Invite Friends Popup Shown');
  };

  const onShareClick = async () => {
    setShowInvitePopup(false);
    setShowStartPopup(false);
    track('Invite Friends Clicked');
    void share({
      title: '',
      text: 'Lets play Doodle Dash together!',
      url: getYardInviteLink(controller.yard.id),
    });
  };

  return (
    <>
      <div className={'yard-and-display-screen-root'}>
        <PopupContainer show={showInvitePopup} onOutClick={() => setShowInvitePopup(false)}>
          <div
            style={{
              fontSize: '4.3vh',
              marginTop: '4vh',
            }}
          >
            Introducing Invite Friends!
            <div style={{ fontSize: '3vh', marginTop: '2vh' }}>
              You can now play with your friends who are not nearby.
            </div>
          </div>
          <div className={'yard-and-display-screen_invite-friends'} />
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <GameButton variant={0} onClick={onShareClick}>
              Lets go!
            </GameButton>
            <GameButton variant={1} size={'sm'} onClick={() => setShowInvitePopup(false)}>
              Maybe later..
            </GameButton>
          </div>
        </PopupContainer>
        <PopupContainer show={showStartPopup} onOutClick={() => setShowStartPopup(false)}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', flexGrow: 1 }}>
            {controller.yard.controllers.length > 1 && (
              <div
                style={{
                  fontSize: '4.3vh',
                  marginBottom: controller.yard.controllers.length < 4 ? '1vh' : '4vh',
                  marginTop: '4vh',
                }}
              >
                Is everyone ready to start?
              </div>
            )}
            {controller.yard.controllers.length < 4 && (
              <div
                style={{
                  fontSize: '3vh',
                  marginBottom: controller.yard.controllers.length === 1 ? '1vh' : '4vh',
                  textAlign: 'center',
                  width: '100%',
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {controller.yard.controllers.length === 1 ? (
                  <SinglePlayerPopupBody inviteClick={onShareClick} startGameClick={startPrivateGame} />
                ) : (
                  <div>
                    Try bringing more friends to play <br />
                    Team Vs Team!
                  </div>
                )}
              </div>
            )}
          </div>
          {controller.yard.controllers.length > 1 && (
            <>
              <GameButton variant={0} onClick={startPrivateGame}>
                Lets go!
              </GameButton>
              <GameButton variant={1} onClick={() => setShowStartPopup(false)}>
                Wait for more people
              </GameButton>
            </>
          )}
        </PopupContainer>

        {shouldShowDev && <div className={'yard-and-display-test-text'}>DEV MODE</div>}
        <EditableImage controller={controller} onProfileUpdate={onProfileUpdate} />
        <NameWithDialog name={controller.profile.name} onProfileUpdate={onProfileUpdate} />
        <div style={{ flexGrow: 1 }} />
        <GameButton variant={0} onClick={handleInviteClick}>
          Invite remote friends
        </GameButton>
        <GameButton variant={1} onClick={() => setShowStartPopup(true)}>
          {controller.yard.controllers.length === 1
            ? 'Start single player!'
            : controller.yard.controllers.length <= 3
            ? 'Start co op!'
            : 'Start Team vs Team!'}
        </GameButton>
        <div
          className={'yard-and-display_hidden-dev-btn'}
          onClick={() =>
            setDevClickCount((c) => {
              if (c + 1 > MIN_DEV_CLICKS && c === MIN_DEV_CLICKS) {
                setDevState(true);
              }

              return c + 1;
            })
          }
        />
      </div>
    </>
  );
};

export const NameWithDialog = ({
  name,
  onProfileUpdate,
}: {
  name: string;
  onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void;
}) => {
  const isDefaultName = name === DEFAULT_NAME;
  const [isEditingName, setIsEditingName] = useState<string | null>(isDefaultName ? '' : null);

  return (
    <>
      <GameButton variant={3} onClick={() => setIsEditingName(name)}>
        {name}
        <div
          style={{
            width: '5vh',
            height: '5vh',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            position: 'absolute',
            right: '4vh',
          }}
        />
      </GameButton>
      <NameDialog
        isOpen={isEditingName !== null}
        onClose={() => setIsEditingName(null)}
        name={name}
        onProfileUpdate={onProfileUpdate}
      />
    </>
  );
};

export const NameDialog = ({
  isOpen,
  onClose,
  onProfileUpdate,
  name,
}: {
  isOpen: boolean;
  onClose: () => void;
  onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void;
  name: string;
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      TransitionComponent={Slide}
      TransitionProps={{
        // @ts-expect-error
        direction: 'up',
      }}
    >
      <Background>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <IconButton className={'take-pic-dialog-close-icon-button'} onClick={onClose}>
            <Close />
          </IconButton>
          <div style={{ fontSize: 32 }}>{name === DEFAULT_NAME ? 'Choose a name' : 'Edit your name'}</div>
          <SubmittableInput
            submitText={'Submit'}
            placeholder={'Name...'}
            defaultValue={name === DEFAULT_NAME ? '' : name}
            forceEnglish={false}
            onSubmit={(val) => {
              onProfileUpdate({ name: val });
              onClose();
            }}
          />
        </div>
      </Background>
    </Dialog>
  );
};

export const EditableImage = ({
  onProfileUpdate,
  controller,
  editable,
}: {
  onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void;
  controller: YardWithDisplayController;
  editable?: boolean;
}) => {
  const handleCameraClick = () => {
    if (editable !== false) {
      track('Selfie Button Clicked');
      setIsTakingPic(true);
    }
  };

  const [isTakingPic, setIsTakingPic] = useState(false);
  return (
    <div className={'yard-and-display_user-image-container'}>
      <TakePicDialog
        onTakePic={(data) => {
          onProfileUpdate({ avatarUrl: data ?? undefined });
          setIsTakingPic(false);
        }}
        onClose={() => setIsTakingPic(false)}
        open={isTakingPic}
      />
      <div className={'yard-and-display-screen-picture-container'}>
        <div
          className={'yard-and-display-screen-picture'}
          style={{
            backgroundImage: `url(${controller.profile.avatarUrl})`,
          }}
        />
        {editable !== false && <div onClick={handleCameraClick} className={'yard-and-display_camera-icon'} />}
      </div>
    </div>
  );
};

async function share(data: ShareData): Promise<void> {
  (window.navigator as any).share(data, SHARE_POLYFILL_OPTIONS).catch((error: any) => {
    console.error(error);
  });
}

const SHARE_POLYFILL_OPTIONS = {
  copy: true,
  email: true,
  print: true,
  sms: true,
  messenger: false,
  facebook: false,
  whatsapp: true,
  twitter: true,
  linkedin: false,
  telegram: true,
  skype: false,
  pinterest: false,
};

const SinglePlayerPopupBody = ({
  startGameClick,
  inviteClick,
}: {
  startGameClick: () => void;
  inviteClick: () => void;
}) => {
  return (
    <div className={'yard-and-display_single-player'}>
      <div className={'yard-and-display_hello-container'}>
        Hey there! <div className={'yard-and-display_hello'} />
      </div>
      Doodle Dash is a blast <br />
      with 2-3 players
      <div className={'yard-and-display_coop'} />
      And even crazier with 4+
      <div className={'yard-and-display_vs'} />
      But don't worry..
      <br /> You can still have fun
      <br />
      doodling solo
      <div className={'yard-and-display_single-btn-container'} style={{ display: 'flex' }}>
        <GameButton variant={0} onClick={inviteClick} size={'sm'}>
          Invite
        </GameButton>
        <GameButton variant={2} onClick={startGameClick} size={'sm'}>
          Doodle now
        </GameButton>
      </div>
    </div>
  );
};
