import React, { useRef } from 'react';
import { useGameObject } from '../../store/GameContext';
import styles from './Playing.module.css';
import { valueToColor } from '@magicyard/magicsnakes-game/src/Types';
import { PlayerID } from 'boardgame.io';
import { useDriftDbSocketContext } from '@magicyard/shared/src/DriftDbSocketContext';

export const Playing = () => {
  const { G, moves, playerID, ctx } = useGameObject();
  const socket = useDriftDbSocketContext();
  return (
    <div className={styles.root} style={{ background: valueToColor[-1] }}>
      <DPad
        playerID={playerID}
        onClick={(state) => {
          socket.send({
            key: 'broadcast',
            type: 'push',
            action: { type: 'relay' },
            value: {
              data: { ...state, playerId: playerID },
            },
          });
        }}
      />
    </div>
  );
};

export const Button = ({
  onClick,
  disabled,
  playerID,
  children,
}: {
  onClick: () => void;
  disabled: boolean;
  playerID: PlayerID;
  children: React.ReactNode;
}) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.button} ${!disabled ? styles.buttonOn : styles.buttonOff}`}
      style={{ background: !disabled ? valueToColor[playerID] : valueToColor[playerID] }}
    >
      {children}
    </div>
  );
};

type LeftRight = 'left' | 'right';

const DPad = ({
  onClick,
  playerID,
}: {
  playerID: string;
  onClick: (state: { left?: boolean; right?: boolean }) => void;
}) => {
  const didHandleDown = useRef<Record<LeftRight, boolean>>({ left: false, right: false });
  const didHandleUp = useRef<Record<LeftRight, boolean>>({ left: false, right: false });

  const downHandler = (val: LeftRight) => {
    if (!didHandleDown.current[val]) {
      didHandleUp.current[val] = false;
      didHandleDown.current[val] = true;
      onClick({ [val]: true });
    }
  };

  const upHandler = (val: LeftRight) => {
    if (!didHandleUp.current[val]) {
      didHandleUp.current[val] = true;
      didHandleDown.current[val] = false;
      onClick({ [val]: false });
    }
  };

  return (
    <div className={styles.dPad}>
      <div className={styles.leftRight}>
        <div
          className={`${styles.dPadArrow} ${styles.arrowLeft}`}
          style={{ background: valueToColor[playerID] }}
          onTouchStart={() => downHandler('left')}
          onTouchEnd={() => upHandler('left')}
          onMouseDown={() => downHandler('left')}
          onMouseUp={() => upHandler('left')}
        >
          <div className={styles.dPadArrowIcon} style={{ transform: 'rotate(180deg)' }} />
        </div>
        <div
          className={`${styles.dPadArrow} ${styles.arrowRight}`}
          style={{ background: valueToColor[playerID] }}
          onTouchStart={() => downHandler('right')}
          onTouchEnd={() => upHandler('right')}
          onMouseDown={() => downHandler('right')}
          onMouseUp={() => upHandler('right')}
        >
          <div className={styles.dPadArrowIcon} />
        </div>
      </div>
    </div>
  );
};
