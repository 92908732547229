import { Client } from 'boardgame.io/react';
import Board from './components/Board';
import { MagicSnakes, GameObject } from '@magicyard/magicsnakes-game/src/Game';
import { getMultiplayerMode } from '@magicyard/utils';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { usePlatformController } from '@magicyard/shared/platform/hooks/usePlatformController';
import Background from './components/Background/Background';
import './App.css';
import { YardAndDisplayScreen } from './components/YardAndDisplayScreen';
import { YardScreen } from './YardScreen';
import { NavigationBar } from './components/navigation-bar/NavigationBar';
import { LoaderTemplate } from './components/loader-template/LoaderTemplate';
import { assertIsDefined } from '@magicyard/magicsnakes-game/src/utils/typeUtils';
import { CONFIG } from '@magicyard/utils';
import { identify, initAnalytics, track, updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { AppBody } from './AppBody';
import { InQueue } from './InQueue';
import { SubmittableInput } from './components/submittable-input/SubmittableInput';
import { ToggleFullScreenButton } from './components/ToggleFullScreenButton';
import { OrientationMessage } from './OrientationMessage';
import {
  Communication,
  GameController,
  YardController,
  YardWithDisplayController,
  YardWithQueueController,
} from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { VoiceChatState } from '@magicyard/shared/platform/lib/api';
import { MicContext, MicProvider } from './MicHeader';
import { GameButton } from '@magicyard/magicsnakes-shared/components/GameButton/GameButton';

interface MainGameLoadingProps {
  controller: YardWithDisplayController;
}

const NUMBER_OF_GAMES_FINISHED_KEY = 'n_g_f';
export const getNumberOfPlayAgain = (): number => {
  return +(localStorage.getItem(NUMBER_OF_GAMES_FINISHED_KEY) ?? 0);
};

export const incNumberOfPlayAgain = () => {
  return localStorage.setItem(NUMBER_OF_GAMES_FINISHED_KEY, '' + (getNumberOfPlayAgain() + 1));
};

export const DEFAULT_NAME = 'Choosing name...';
const MainGameLoading = (props: MainGameLoadingProps) => {
  const { controller } = props;

  useEffect(() => {
    updateAnalyticsData({ controllerId: controller.profile.id });
  }, [controller]);

  useEffect(() => {
    track('Game Loading Appeared');
  }, []);

  return (
    <AppBody title={'Game is loading'}>
      <div></div>
    </AppBody>
  );
};

const OnInitLoading = () => {
  useEffect(() => {
    track('Init Loading Appeared');
  }, []);
  return (
    <AppBody title={''}>
      <LoaderTemplate title={'Loading...'} />
    </AppBody>
  );
};

const useReloadOnPageBlur = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      // Reload when coming BACK into the page
      if (!document.hidden && !(document as any).webkitHidden && document.visibilityState === 'visible') {
        window.location.reload();
      }
    };
    window.document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export const App = () => {
  useEffect(() => {
    initAnalytics(CONFIG.MIXPANEL_API_KEY, 'Controller', 'Standalone', 'gptrivia');
    track('App Launched');
  }, []);

  useReloadOnPageBlur();
  const query = new URLSearchParams(window.location.search);
  const [devState, setDevState] = useState(false);
  const [bgio, setBgio] = useState<null | JSX.Element>(null);
  const [lastMatchID, setLastMatchID] = useState<string | null>(null);

  const { toRender, title, hasVoiceOverlay, isGame } = usePlatformController<{
    toRender: React.ReactNode;
    title?: string;
    isGame?: boolean;
    hasVoiceOverlay?: {
      comms: Communication;
      controller: GameController | YardController | YardWithDisplayController;
    };
  }>(
    {
      onGameLoading({ controller, communication }) {
        identify(controller.profile.id);
        return {
          toRender: <MainGameLoading controller={controller} />,
          hasVoiceOverlay: { comms: communication, controller: controller },
        };
      },
      onInitialLoading() {
        return {
          toRender: <OnInitLoading />,
        };
      },
      onGame({ controller, onStartAgain, onStartAgainOnline, communication }) {
        identify(controller.profile.id);
        const urlParams = new URLSearchParams(
          new URL(
            (
              controller.gameStartArgs as {
                url: string;
              }
            ).url
          ).search
        );
        const playerID = urlParams.get('playerID');
        identify(controller.profile.id);
        assertIsDefined(playerID);
        const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID || 'default';
        const serverURL = urlParams.get('serverURL') ?? undefined;
        if (bgio === null || matchID !== lastMatchID) {
          updateAnalyticsData({ matchId: matchID });
          track('Game Started');
          const ClientInstance = Client({
            game: MagicSnakes,
            loading: () => (
              <AppBody title={''}>
                <LoaderTemplate title={'Loading...'} />
              </AppBody>
            ),
            board: (G) => (
              <Board
                key={'board'}
                G={G as GameObject}
                onStartNewGame={() => {
                  track('Play Again Clicked');
                  // if (controller.yard.controllers.length >= 3) {
                  onStartAgain({ isPlayAgain: true });
                  incNumberOfPlayAgain();
                  // }
                  // else {
                  //   onStartOnlineAgain('doodledash');
                  // }
                }}
              />
            ),
            debug: devState,
            multiplayer: getMultiplayerMode(serverURL),
          });
          setLastMatchID(matchID);
          setBgio(
            <Fragment key={'bgio'}>
              <ToggleFullScreenButton />
              <OrientationMessage />
              <ClientInstance playerID={playerID} matchID={matchID} />
            </Fragment>
          );
        }
        return {
          toRender: <></>,
          isGame: true,
          hasVoiceOverlay: { comms: communication, controller: controller },
        };
      },
      onInvalidYard({ controller, onDisplayCodeEntered }) {
        identify(controller.profile.id);
        return {
          toRender: <InvalidYard onCodeEntered={onDisplayCodeEntered} />,
          title: '',
        };
      },
      onYard({ controller, onRoomCodeEntered, communication, onProfileUpdate }) {
        identify(controller.profile.id);
        return {
          toRender: (
            <AppBody title={''}>
              {/*<MicHeader comms={communication} controller={controller} />*/}
              <YardScreen
                onCodeEntered={onRoomCodeEntered}
                controller={controller}
                comms={communication}
                onProfileUpdate={onProfileUpdate}
              />
            </AppBody>
          ),
          title: '',
        };
      },
      onYardWithDisplay({ controller, communication, onSubmitOnline, onSubmitLocal, onProfileUpdate }) {
        identify(controller.profile.id);
        return {
          hasVoiceOverlay: { comms: communication, controller: controller },
          toRender: (
            <AppBody title={''}>
              <YardAndDisplayScreen
                setDevState={setDevState}
                controller={controller}
                onSubmitOnline={onSubmitOnline}
                onSubmitLocal={onSubmitLocal}
                onProfileUpdate={onProfileUpdate}
              />
            </AppBody>
          ),
        };
      },
      onOnlineQueue({ controller, onLeaveQueue, communication }) {
        identify(controller.profile.id);
        return {
          // hasVoiceOverlay: { comms: communication, voiceChatState: controller },
          toRender: <QueueContainer controller={controller} onLeaveQueue={onLeaveQueue} />,
        };
      },
    },
    { displayId: query.get('displayId'), yardId: query.get('yardId') },
    `https://avatars.dicebear.com/api/croodles/${Math.random()}.svg`,
    DEFAULT_NAME
  );

  return (
    <MicProvider>
      {hasVoiceOverlay !== undefined && (
        <>
          {/*<MicHeader comms={hasVoiceOverlay.comms} controller={hasVoiceOverlay.controller} />*/}

          {/*<VoiceChatActivatedOverlay*/}
          {/*  comms={hasVoiceOverlay.comms}*/}
          {/*  voiceChatState={hasVoiceOverlay.controller.yard.voiceChatState}*/}
          {/*  subtitle={'Someone joined your yard and voice chat was activated!'}*/}
          {/*/>*/}
        </>
      )}
      {isGame ? (
        bgio
      ) : (
        <Background>
          <NavigationBar header={title} />
          {toRender}
        </Background>
      )}
    </MicProvider>
  );
};

type QueueContainerProps = {
  onLeaveQueue: () => void;
  controller: YardWithQueueController;
};
const QueueContainer = (props: QueueContainerProps) => {
  const { onLeaveQueue, controller } = props;
  const queueId = controller.yard.queue.id;

  useEffect(() => {
    if (queueId === null) {
      return;
    }

    track('Queue Page Loaded', { queueId: queueId });
  }, [queueId]);

  const handleLeaveQueue = () => {
    track('Player Cancelled Queue');
    onLeaveQueue();
  };

  return (
    <AppBody title={'In queue'}>
      <InQueue onLeaveQueue={handleLeaveQueue} controller={controller} />
    </AppBody>
  );
};

const InvalidYard = ({ onCodeEntered }: { onCodeEntered: (value: string) => void }) => {
  useEffect(() => {
    track('Enter Room Code Loaded');
  }, []);

  const handleSubmit = (v: string) => {
    track('Room Code Entered', { roomCode: v });
    onCodeEntered(v);
  };

  return (
    <AppBody title={'Enter room code'}>
      <SubmittableInput
        defaultValue={''}
        type={'tel'}
        pattern="[0-9]*"
        inputMode={'numeric'}
        forceEnglish={false}
        onSubmit={handleSubmit}
        submitText={'Submit'}
      />
    </AppBody>
  );
};

export const VoiceChatActivatedOverlay = ({
  comms,
  voiceChatState,
  subtitle,
}: {
  comms: Communication;
  voiceChatState: VoiceChatState | null;
  subtitle: string;
}) => {
  const { localMicState, setLocalMicState } = useContext(MicContext);
  const isVoiceActive = voiceChatState !== null;
  const [show, setShow] = useState(false);
  const didShow = React.useRef(false);

  useEffect(() => {
    if (isVoiceActive && localMicState === null && !didShow.current) {
      setShow(true);
      didShow.current = true;
    } else {
      setShow(false);
    }
  }, [isVoiceActive, localMicState]);

  return (
    <PopupContainer show={show}>
      <div className={'app-voice_chat_overlay_title'}>
        Voice chat activated
        <div className={'app-voice_chat_overlay_subtitle'}>{subtitle}</div>
      </div>
      <div className={'app-voice_chat_icon'} />
      <div className={'app-voice_chat_overlay_btn'}>
        <GameButton
          onClick={async () => {
            track('Joined voice chat', { voiceActionSource: 'Popup' });
            setLocalMicState('loading');
            const res = (await comms.voice?.micState?.join()) ?? null;
            setLocalMicState(res);
            setShow(false);
          }}
          variant={0}
        >
          {localMicState === 'loading' ? 'Loading..' : 'Join now!'}
        </GameButton>
        <GameButton
          onClick={() => {
            track('Voice chat not approved', { voiceActionSource: 'Popup' });
            setShow(false);
          }}
          variant={2}
        >
          Maybe later..
        </GameButton>
      </div>
    </PopupContainer>
  );
};

export const PopupContainer = ({
  show,
  children,
  onOutClick,
}: {
  show: boolean;
  children: React.ReactNode;
  onOutClick?: () => void;
}) => {
  const [isHiding, setIsHiding] = useState(show);
  useEffect(() => {
    if (!isHiding && show) {
      setIsHiding(true);
    }
  }, [isHiding, show]);
  return (
    <div
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onOutClick?.();
        }
      }}
      className={`app-voice_chat_overlay_root ${
        show ? 'app-voice_chat_overlay_root_show' : isHiding ? 'app-voice_chat_overlay_root_hide' : ''
      }`}
      onAnimationEnd={(e) => {
        if (e.animationName === 'app-voice_chat_overlay_root_hide') {
          setIsHiding(false);
        }
      }}
    >
      <div className={`app-voice_chat_overlay_bg`}>
        <div className={'app-voice_chat_overlay_body'}>{children}</div>
      </div>
    </div>
  );
};
