import React from 'react';
import { useGameObject } from '../../store/GameContext';
import { Button } from './Playing';
import { track, updateAnalyticsData } from '@magicyard/shared/src/localAnalytics';

const playersWithBots = 5;
const minPlayers = 1;

export const Tutorial = () => {
  const { G, moves, playerID } = useGameObject();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={{ color: 'black', fontSize: 32, position: 'absolute', top: '10%' }}>
        {G.players.length > minPlayers ? 'Select game mode' : 'Click to start!'}
      </div>
      {G.players.length > minPlayers && (
        <Button
          onClick={() => {
            updateAnalyticsData({ mode: 'Without bots', numOfPlayers: G.players.length });
            track('Game mode chosen');
            moves.endTutorial(G.players.length);
          }}
          disabled={false}
          playerID={playerID}
        >
          {G.players.length} players
        </Button>
      )}
      {G.players.length < playersWithBots && (
        <Button
          onClick={() => {
            updateAnalyticsData({ mode: 'With bots', numOfPlayers: playersWithBots });
            track('Game mode chosen');
            moves.endTutorial(playersWithBots);
          }}
          disabled={false}
          playerID={playerID}
        >
          {playersWithBots} players{' '}
          {G.players.length === playersWithBots
            ? ''
            : `\n(With ${playersWithBots - G.players.length} Bot${
                playersWithBots - G.players.length === 1 ? '' : 's'
              })`}
        </Button>
      )}
    </div>
  );
};
